.card-root {
  position: relative;
  width: 100%;
  border-radius: 15px;
  border: 1px solid rgba(161, 161, 161, 0.48);
}
.image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  border-radius: 15px;
  background-color: white;
}
.image-container:hover .card-details{
  opacity: 1;
  transition: all .25s;
}
.image-container img{
  max-width: 100%;
}
.card-content {
  width: 100%;
  margin-top: 0px;
  z-index: 1;
  background-color: rgb(0, 4, 7);
  border-radius: 0px 0px 15px 15px;
  padding: 20px 25px 25px;
  display:flex;
  gap:10px;
}
.card-content p{
  margin-bottom: 0;
}
.card-details{
    opacity: 0;
    transition: all .25s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000ea;
    z-index: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 20px;
    overflow-y: auto;
}
.card-details ul{
  list-style: none;
  padding: 0;
  text-align: center;
}
.trait-type, .trait-value{
  margin: 0;
}
.trait-type{
  color:rgb(112, 112, 112)
}
.trait-value{
  color: #fff;
}

.card-details::-webkit-scrollbar-thumb {
  background: none
}
