@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'poppins';
  src: local('poppins'), url(/static/media/Poppins.8081832f.ttf) format('truetype');
}

@font-face {
  font-family: 'poppins-semibold';
  src: local('poppins-semibold'),
    url(/static/media/Poppins-SemiBold.cce5625b.ttf) format('truetype');
}

@font-face {
  font-family: 'poppins-medium';
  src: local('poppins-medium'),
    url(/static/media/Poppins-Medium.9e1bb626.ttf) format('truetype');
}


@font-face {
  font-family: 'subspace';
  src: local('subspace'), url(/static/media/Subspace.7dcc8224.otf) format('opentype');
}
@font-face {
  font-family: 'teko';
  src: local('teko'), url(/static/media/Teko-SemiBold.3374e10e.ttf) format('truetype');
}

.thumbnail{
  position : absolute;
  top : 0 ;
  z-index : -5000;
  width : 0px;
  height : 0px;
}
.thumbnail img{
      object-fit : cover;
      width : 0px;
      height : 0px;
}

.bn-onboard-custom{
  z-index: 2323;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
/* ::-webkit-scrollbar-track {
  background: #000000;
} */

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(110, 110, 110);
}

.clickable {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.clickable:hover {
  color:#be1fda;
}


html, body{
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  background: #faf9f5;
  color: #969da3;
  height: 100%;
}

h1,h2,h3,h4,h5{
  font-weight: 700;
  color: #ffffff;
}
h4,h5{
  font-weight: 500;
  color: #ffffff;
}
#root{
  height: 100%;
}
#application {
  padding-top: 0;
  background: none;
  position: relative;
  min-height: 100%;
  /* background-color: rgba(0, 0, 0, 0.753); */
  /* padding-bottom: 100px; */
}
.btn.round {
  border-radius:0.6rem;
}
.btn-green {
  background-color: #94E316;
  color: black;
}

.btn-peach {
  border-color:#be1fda;
  border: solid 3px #be1fda;
  background-color: #be1fda;
  color: black;
  white-space: pre-wrap;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.btn-green.focus, .btn-green:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(148,227,22,.25);
}

.btn-peach.focus, .btn-peach:focus {
 outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(189, 92, 153, 0.25);
}

.btn-outline {
  background: none;

  border:solid 3px;
}

.btn-outline.btn-peach{
  border-color:#be1fda;
  color:white;
}

.btn-outline.btn-peach:hover{
  background-color:#be1fda;
  color:#fff;
}

.btn-peach:hover{
  background-color:#1B1E25;
  color:#be1fda;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: #3f3d3a;
}

.modal-header {
  border-bottom: 1px solid #949393;
}
.modal-footer {
  border-top: 1px solid #949393;
}

.close {
  color: #fff;
  text-shadow: 0 1px 0 #333;
  opacity: .5;
}

.toastContainer {
  z-index:2000;
}

.text-peach {
  color: #be1fda;
}

.text-lilac {
  color: #771197;
}

.bold-text{
  font-weight: 400;
}

/* modal overrides... */
.web3modal-provider-container{
  padding: 4px !important;
}
.web3modal-provider-icon{
  width: 32px !important;
  height: 32px !important;
}
.web3modal-provider-name{
  font-size: 1.1em !important;
  margin-top: 0.3em !important;
}
.web3modal-provider-description{
  font-size: 0.9em !important;
  margin: 0.2em 0px !important;
}
.web3modal-modal-card{
  max-width:710px !important;
}
.nft-card{
    position:relative;
    overflow:hidden;
    border-radius: 1em;
    margin-bottom: 1.5em;
    width:100%;
    padding-top: 134.72%;
    background:#445;
    color:white;
    box-shadow: 0 5px 2px #000;
}
.home{
    z-index: 123123123213;
}
.ReactModal__Overlay{
    z-index: 22;
}
.ReactModal__Content{
    background-color: transparent!important;
    border: none!important;
}
.nft-card img{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    transition: all 0.4s ease-in-out;
}
.nft-card img:hover{
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}
.nft-card .nft-type{
    position: absolute;
    z-index: 1;
    top:5%;
    left: 5%;
    background-color: #000;
    color: #fff;
    font-weight: 600;
    font-size: 0.9em;
    line-height: 0.9em;
    border-radius: 0.6rem;
    padding: 0.6em;
    opacity: 1;
    cursor: pointer;
}

.nft-card .info-panel{
    width:100%;
    height:45%;
    position: absolute;
    left: 0;
    bottom: -65%;
    background: rgba(0,0,0,0.7);
    pointer-events: none;
    z-index: 1;
    transition: bottom 0.4s ease-out;
}

.nft-card:hover .info-panel{
    bottom: -1px;
}



.info-panel .link-panel {
    position: absolute;
    bottom: 0;
    width:100%;
}

.info-panel .link-panel .lnk{
    display: inline-block;
    width:2em;
    height: 2em;
    border-radius: 0.4em;
    margin-right: 0.4em;
    padding:0.2rem;
    pointer-events:all;
}

.lnk.opensea{
    background:#3f6ed3;
}
.lnk.etherscan{
    background:#fff;
}

.info-panel .link-panel .lnk img{
    position: relative;
    max-height: 48px;
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
    transition: none;
}

.lnk img:hover{
    -webkit-transform: none;
            transform: none;
}

.info-panel .avatar{
    padding:0;
}

.info-panel .avatar img {
    width: 100%;
    object-fit: contain;
    object-position: top;
}

.info-panel .details{
    padding-top: 0.7rem;
}

.info-panel h5 {
    font-size: 1em;
    color: #be1fda;
    margin-bottom: 0.15em;
}

.info-panel p {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.2em;
    max-height: 2.8em;
    display: block;
    overflow: hidden;
}

.info-panel .gray-text{
    color:#aaa;
}

.filters {
    position: fixed;
    top:80px;
    left:0;
    right: 0;
    z-index: 2;
    width: 100%;
}

.filters .content{
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 6px 6px rgba(0,0,0,0.7);
}

.filter-item{
    text-align: center;
    align-self: center;
    height: -webkit-min-content;
    height: min-content;
    width: 25%;
    font-size: 1rem;
}


.filter-item h5{
    font-size:1em;
    color:#333;
    margin-bottom: 0.5em;
    margin-top: 0.2em;
}
.filter-item .selected-option{
    font-size:1em;
    color:#be1fda;
    position: relative;
    text-transform: capitalize;
    cursor: pointer;
}

.filter-item .selected-option .dropdown{
    display:none;
    position: absolute;
    width:100%;
    padding-top: 1.5rem;
}

.dropdown .dd-container{
    max-height: 45vh;
    overflow: auto;
    background-color: white;
    border-radius: 1rem;
    box-shadow: 0 6px 6px rgba(0,0,0,0.7);
    padding:1rem;
}

.filter-item .selected-option:hover .dropdown{
    display:block;
}

.dd-container ul{
    list-style: none;
    padding: 0;
    margin:0;  
    text-align: left;  
}

.dd-container ul li{
    color:#333;
    cursor:pointer;
    font-size: 1em;
}

.dd-container ul li:hover{
    color:#be1fda;
}


.id-filter {
    padding: 0.2rem 0 0.2rem 0.2rem;
    background-color: #E4E1E1;
    border-radius: 0.75rem;
    margin-left: auto;
}

.id-filter input, .id-filter input:focus{
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    color:#333;
    width: 75%;
    padding-left: 5px;
}

.id-filter button{

    background:#be1fda;
    border:none;
    width:2.5rem;
    height:2.5rem;
    border-radius: 0.65rem;
    color: white;
    outline: none;
}

.id-filter button img{
    width:60%;
    object-fit: contain;
    object-position: center;
}



.filters .btn-outline.btn-peach{
    padding-top:0.3rem;
    padding-bottom: 0.3rem;
}

.back-top{
    position: fixed;
    bottom: 1.5rem;
    right: 1.5rem;
    width: 3rem;
    height: 3rem;
    background: #be1fda;
    color: white;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
    line-height: 2.7rem;
    z-index: 12;
    font-weight: 600;
    font-size: 1.6rem;
}
.checkbox {
    width: 20px;
    height: 20px;
    margin-top: 10px;
}

/****** Explorer *******/










@media (max-width: 1200px)
{
    .info-panel {
       font-size:0.8rem;
    }
}

@media (max-width: 992px)
{
    .info-panel {
       font-size:0.7rem;
       height:65%;
    }

    .info-panel .details{
        padding-top: 0.4rem;
    }

 

}

@media (max-width: 768px)
{
   
    .filters {
       position: relative;
       top: 0;
    }

    .info-panel {
        font-size:2rem;
        height:65%;
     }

     .info-panel .details{
        padding-top: 1rem;
    }

    .filter-item {
        font-size: 0.7rem;
    }

    .filter-item .selected-option .dropdown {
        width: 220%;
        left:0;
    }
   

}
.card-root {
  position: relative;
  width: 100%;
  border-radius: 15px;
  border: 1px solid rgba(161, 161, 161, 0.48);
}
.image-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  cursor: pointer;
  border-radius: 15px;
  background-color: white;
}
.image-container:hover .card-details{
  opacity: 1;
  transition: all .25s;
}
.image-container img{
  max-width: 100%;
}
.card-content {
  width: 100%;
  margin-top: 0px;
  z-index: 1;
  background-color: rgb(0, 4, 7);
  border-radius: 0px 0px 15px 15px;
  padding: 20px 25px 25px;
  display:flex;
  grid-gap:10px;
  gap:10px;
}
.card-content p{
  margin-bottom: 0;
}
.card-details{
    opacity: 0;
    transition: all .25s;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000ea;
    z-index: 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 20px;
    overflow-y: auto;
}
.card-details ul{
  list-style: none;
  padding: 0;
  text-align: center;
}
.trait-type, .trait-value{
  margin: 0;
}
.trait-type{
  color:rgb(112, 112, 112)
}
.trait-value{
  color: #fff;
}

.card-details::-webkit-scrollbar-thumb {
  background: none
}

.filter-container{
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: 2;
    width: 100%;
    background-color: #faf9f5f5;
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 29%);
    max-width: 1140px;
    border-radius: 15px;
    z-index: 3;
}

.filter-container-inner{
    margin: 10px;
    display:flex;
    flex-wrap: wrap;
    width: 85%
}

.id-input-filter{
    padding-right: 1rem;
}

.id-input-filter input{
    background: transparent;
    color:black;
    border: none;
    outline: none;
    max-width: 100px;
}

.id-input-filter input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fa8006;
    opacity: 1; /* Firefox */
  }

.id-input-filter input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fa8006;
    opacity: 1; /* Firefox */
  }
  
  .id-input-filter input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fa8006;
  }
  
  .id-input-filter input::-ms-input-placeholder { /* Microsoft Edge */
    color: #fa8006;
  }

/* .custom-filter-select select{
	margin-top: 0.4rem;
	margin-bottom: 0.4rem;
	padding-left: 0.6rem;
	padding-right: 0.6rem;
	border: none;
	background: transparent;
	box-shadow: none;
	color: #000;
	outline: none;
  }

  .select-parent{
	border: 2px solid #000;
	.custom-filter-select
	padding-right: 5px;
  }
  .select-parent:focus-within{
	border: 2px solid #fa8006;
  }

.filter-container select{

    font-size: 16px;
}
 */

 .custom-filter-select{
	cursor: pointer;
	min-width: 100px;
 }

.filter-container label{
	color: #000;
    font-size: 16px;
    font-weight: 500;
	cursor: pointer;
}

.filter-container label span.arrow {
	font-size: 12px;
}

.filter-container label.open span.arrow {
	color:#fa8006;
}

.custom-filter-select{
	position: relative;
}


.custom-filter-select .filter-dropdown{
	min-width:180px;
	max-height:300px;
	overflow-y: auto;
	color: #000;
    font-size: 14px;
    font-weight: 400;
	text-align: left;
	padding:1rem;
	position: absolute;
	display: none;
	top: 32px;
	left: 0;
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 0 0 8px rgba(0,0,0,0.5);
	z-index: 5;

}

.custom-filter-select .filter-dropdown::-webkit-scrollbar {
    width: 4px!important;
    height: 8px;
  }
.custom-filter-select .filter-dropdown::-webkit-scrollbar-track {
background: transparent;
} 
/* Handle */
.custom-filter-select .filter-dropdown::-webkit-scrollbar-thumb {
background: rgb(252, 108, 3);
border-radius: 10px;
}

.filter-container label.open + .filter-dropdown{
	display: block;
}

.filter-value {
	color:#fa8006;
	font-size: 14px;
	font-weight: 600;
}

.cust-filter-item.selected{
	font-weight: 600;
}
.card-overlay{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 34343445435;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
}
.close-btn{
    background-color:transparent;
    border: none;
    position:absolute;
    right:10px;
    top:10px;
    color:#fff;
    z-index: 99;
}
.close-btn img{
    width: 35px;
    height: 35px;
}
.close-btn:hover{
    cursor: pointer;
}
.close-btn:focus{
    outline: none;
}
