.filter-container{
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    z-index: 2;
    width: 100%;
    background-color: #faf9f5f5;
    box-shadow: 0px 0px 15px 2px rgb(0 0 0 / 29%);
    max-width: 1140px;
    border-radius: 15px;
    z-index: 3;
}

.filter-container-inner{
    margin: 10px;
    display:flex;
    flex-wrap: wrap;
    width: 85%
}

.id-input-filter{
    padding-right: 1rem;
}

.id-input-filter input{
    background: transparent;
    color:black;
    border: none;
    outline: none;
    max-width: 100px;
}

.id-input-filter input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fa8006;
    opacity: 1; /* Firefox */
  }
  
  .id-input-filter input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #fa8006;
  }
  
  .id-input-filter input::-ms-input-placeholder { /* Microsoft Edge */
    color: #fa8006;
  }
